<template>
  <v-card :color="color" class="ml-auto" dark>
    <div class="d-flex flex-no-wrap justify-space-between">
      <div>
        <v-card-title class="headline" v-text="title"></v-card-title>

        <v-card-subtitle v-text="number" class="display-3"></v-card-subtitle>

        <v-card-actions>
          <v-btn class="ml-2 mt-3" fab icon height="40px" right width="40px">
            <v-icon>mdi-information</v-icon>
          </v-btn>
          {{ footer }}
        </v-card-actions>
      </div>

      <v-avatar class="ma-3" size="125" tile>
        <v-icon size="125">{{ icon }}</v-icon>
      </v-avatar>
    </div>
  </v-card>
</template>

<script>
export default {
  props: ["title", "number", "icon", "color", "footer"]
};
</script>

<style></style>
