<template>
  <v-container fluid>
    <!-- class="d-flex flex-row justify-start" -->
    <v-row>
      <v-col
        cols="12"
        sm="6"
        lg="3"
        xl="3"
        v-for="(item, i) in infocards"
        :key="`col-${i}`"
      >
        <info-card
          :key="i"
          :title="item.title"
          :icon="item.icon"
          :number="item.number"
          :color="item.color"
          :footer="item.footer"
        ></info-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import infoCard from "./components/infoCard";
import { stat } from "../api/dashboard";
export default {
  components: { infoCard },
  data() {
    return {
      infocards: [
        {
          title: "ลงทะเบียนแล้ว",
          icon: "mdi-account-plus",
          number: 0,
          color: "orange",
          footer: ""
        },
        {
          title: "คิว VIP",
          icon: "mdi-account-star",
          number: 0,
          color: "red darken-2",
          footer: ""
        },
        {
          title: "ดำเนินการแล้ว",
          icon: "mdi-account-multiple-check",
          number: 0,
          color: " light-blue ",
          footer: ""
        },
        {
          title: "ลงทะเบียนด้วยตัวเอง",
          icon: "mdi-account-hard-hat",
          number: 0,
          color: "teal",
          footer: ""
        },
        {
          title: "ลงทะเบียนโดยนายจ้าง",
          icon: "mdi-account-plus",
          number: 0,
          color: "green",
          footer: ""
        },
        {
          title: "คิวว่าง(วันนี้)",
          icon: "mdi-account-check-outline",
          number: 0,
          color: "indigo",
          footer: ""
        },

        {
          title: "เมียนมาร์",
          icon: "mdi-head-check",
          number: 0,
          color: "yellow darken-3",
          footer: "ที่ดำเนินการแล้ว"
        },
        {
          title: "กัมพูชา",
          icon: "mdi-head-check",
          number: 0,
          color: "blue darken-3",
          footer: "ที่ดำเนินการแล้ว"
        },
        {
          title: "ลาว",
          icon: "mdi-head-check",
          number: 0,
          color: "green darken-3",
          footer: "ที่ดำเนินการแล้ว"
        }
      ]
    };
  },
  created() {
    this.getStat();
  },
  computed: {
    getRegistered() {
      return this.registered;
    }
  },
  methods: {
    async getStat() {
      let res = await stat();
      // console.log(res);
      const {
        registered,
        vip,
        self,
        success,
        byagent,
        freeSlot,
        mm,
        cb,
        lo
      } = res;

      this.infocards[0].number = registered;
      this.infocards[1].number = vip;
      this.infocards[2].number = success;
      this.infocards[3].number = self;
      this.infocards[4].number = byagent;
      this.infocards[5].number = freeSlot;
      this.infocards[6].number = mm;
      this.infocards[7].number = cb;
      this.infocards[8].number = lo;
    }
  }
};
</script>

<style></style>
